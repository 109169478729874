// ======================
// ===     LOADER     ===
// ======================
$(document).ready(function() {
    $('#loader').addClass('fade');
    // $('#loader').addClass('loaded');
    setTimeout(function() {
        $('#loader').addClass('loaded');
    }, 10);

});

// ======================
// ===    parallax    ===
// ======================
$(document).ready(function() {
    try {
        var rellax = new Rellax('.parallax-background');
        var rellax2 = new Rellax('.parallax-text');
    } catch (err) {
        console.log('rellax not loaded');
        location.reload();
    }
});

// ======================
// ===       AOS      ===
// ======================
$(document).ready(function() {
    try {
        AOS.init({ duration: 500 });
    } catch (err) {
        console.log('AOS not loaded');
    }
});

// ==========================================
// ===        PROJECT IMAGE CENTER        ===
// ==========================================
var figure_width = 0;
var image_width = 0;
var total_shift = 0;
$(document).ready(function() {
    figure_width = $(".figure").width();
    image_width = $(".project_image").width();
    total_shift = -image_width / 2.0 + figure_width / 2.0;
    $('.figure').css({
        '-webkit-transform': 'translate(' + total_shift + 'px, 0)',
        '-moz-transform': 'translate(' + total_shift + 'px, 0)',
        '-ms-transform': 'translate(' + total_shift + 'px, 0)',
        '-o-transform': 'translate(' + total_shift + 'px, 0)',
        'transform': 'translate(' + total_shift + 'px, 0)'
    });
});


// ==========================================
// === Resize listener for About section  ===
// ==========================================
// const widthText = document.querySelector('#title-text');
// const widthContainer = document.querySelector('#title-container');
// const widthText = $("#title-text");
const widthContainerInfo = $(".item-description");
const widthTextInfo = $('[class="title-text"]');

var widthContainer = 0;
$(document).ready(function() {
    var widthText = 0;
    widthTextInfo.each(function() {
        if ($(this).width() > widthText) {
            widthText = $(this).width();
        };
        // $(this).innerWidth();
    });
    widthContainer = widthContainerInfo.width();
    if (widthText + 50 > widthContainer) {
        widthTextInfo.each(function() {
            $(this).addClass("fullwidth");
        });
    };
});

window.addEventListener('resize', function(event) {
    widthTextInfo.each(function() {
        $(this).removeClass("fullwidth");
    });
    var widthText = 0;
    widthTextInfo.each(function() {
        if ($(this).width() > widthText) {
            widthText = $(this).width();
        };
        // $(this).innerWidth();
    });
    widthContainer = widthContainerInfo.width();
    if (widthText + 50 > widthContainer) {
        widthTextInfo.each(function() {
            $(this).addClass("fullwidth");
        });
    };
    // ==========================================
    // ===        PROJECT IMAGE CENTER        ===
    // ==========================================
    figure_width = $(".figure").width();
    image_width = $(".project_image").width();
    total_shift = -image_width / 2.0 + figure_width / 2.0;
    $('.figure').css({
        '-webkit-transform': 'translate(' + total_shift + 'px, 0)',
        '-moz-transform': 'translate(' + total_shift + 'px, 0)',
        '-ms-transform': 'translate(' + total_shift + 'px, 0)',
        '-o-transform': 'translate(' + total_shift + 'px, 0)',
        'transform': 'translate(' + total_shift + 'px, 0)'
    });

}, true);

// ======================
// ===    COOKIES     ===
// ======================
// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
    cookie_name: 'cookie_setings', // default: 'cc_cookie'
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0
    gui_options: {
        consent_modal: {
            layout: 'cloud', // box/cloud/bar
            position: 'bottom center', // bottom/middle/top + left/right/center
            transition: 'slide', // zoom/slide
            swap_buttons: false // enable to invert buttons
        },
        settings_modal: {
            layout: 'box', // box/bar
            // position: 'left',           // left/right
            transition: 'slide' // zoom/slide
        }
    },

    onFirstAction: function(user_preferences, cookie) {
        // callback triggered only once
    },

    onAccept: function(cookie) {
        // ...
    },

    onChange: function(cookie, changed_preferences) {
        // ...
    },

    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <a type="button" data-cc="c-settings" class="cc-link">Manage preferences</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all' // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary' // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' }
                ],
                blocks: [{
                        title: 'Cookie usage',
                        description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://www.windflow.pl/privacy" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [ // list of all expected cookies
                            {
                                col1: 'PHPSESSID',
                                col2: 'windflow.pl',
                                col3: 'session length',
                                col4: 'Your session ID cookie',
                                is_regex: true
                            },
                            {
                                col1: 'cookie_setings',
                                col2: 'windflow.pl',
                                col3: '6 months',
                                col4: 'Your cookie preference',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics', // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [ // list of all expected cookies
                            {
                                col1: '^_ga', // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'Google Analytics cookies',
                                is_regex: true
                            }
                        ]
                    },
                    {
                        title: 'More information',
                        description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:contact@windflow.pl">contact us</a>.',
                    }
                ]
            }
        }
    }
});


///============================================
///============================================
// PHOTOSWIPE
///============================================
///============================================
var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for (var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes 
            if (figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if (figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if (linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if (!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if (childNodes[i].nodeType !== 1) {
                continue;
            }

            if (childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if (index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe(index, clickedGallery);
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
            params = {};

        if (hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if (!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if (pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if (params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
            }

        };

        // PhotoSwipe opened from URL
        if (fromURL) {
            if (options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for (var j = 0; j < items.length; j++) {
                    if (items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
            return;
        }

        if (disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // disable captions and share UI elements
        options.captionEl = false;
        options.shareEl = false;

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll(gallerySelector);

    for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
};

// execute above function
initPhotoSwipeFromDOM('.figure-zoomable');
///============================================
///============================================
///============================================
///============================================



// // $('.tabs').tabslet();
// $('.tabs').tabslet({
//     mouseevent: 'click',
//     attribute: 'href',
//     animation: true
// });


// var mySwiper = new Swiper('.swiper-container', {
//     // Optional parameters
//     direction: 'horizontal',
//     loop: false,
//     slidesPerView: 2,
//     spaceBetween: 0,

//     // Navigation arrows
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//     },
//     breakpoints: {
//         360: {
//             slidesPerView: 1,
//             spaceBetween: 10,
//         },
//         480: {
//             slidesPerView: 1,
//             spaceBetween: 10,
//         },
//         640: {
//             slidesPerView: 1,
//             spaceBetween: 20,
//         },
//         768: {
//             slidesPerView: 1,
//             spaceBetween: 40,
//         },
//         1024: {
//             slidesPerView: 2,
//             spaceBetween: 50,
//         },
//     }

// })

//
//
//
//
//
///============================================
///=====       FORM VALIDATION       ==========
///============================================
// Wait for the DOM to be ready
$(function() {
    // Initialize form validation on the registration form.
    // It has the name attribute "registration"
    $("form[name='contact-form']").validate({
        // Specify validation rules
        rules: {
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                // Specify that email should be validated
                // by the built-in "email" rule
                email: true
            },
            company: {
                required: false,
                minlength: 2
            },
            agree: {
                required: true,
            },
            message: {
                required: true,
                minlength: 10
            }

        },
        // Specify validation error messages
        messages: {
            name: "Please enter your full name",
            email: "Please enter a valid email address",
            agree: "Please agree to privacy policy",
            message: "Please enter your message"
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function(form) {
            ///============================================
            ///=====         CONTACT AJAX        ==========
            ///============================================
            $.ajax({
                type: 'post',
                url: 'phpmail.php',
                data: $('form').serialize(),
                success: function(data) {
                    // console.log(data);
                    JSONdata = JSON.parse(data);
                    // console.log(JSONdata);
                    $("#modal-container").addClass("show");
                    $(".modal-background").addClass("show");
                    // console.log(JSONdata.error);
                    if (JSONdata.error == 0) {
                        $(".modal-bar").addClass("green");
                        $(".modal-bar").removeClass("red");
                        $("#msg_header").text("Success");
                        $("#msg_body").text("Thank you for sending your message!");
                    } else {
                        $(".modal-bar").addClass("red");
                        $(".modal-bar").removeClass("green");
                        $("#msg_header").text("Error");
                        $("#msg_body").text("Message was not sent!");
                    }
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    $("#modal-container").addClass("show");
                    $(".modal-background").addClass("show");
                    $(".modal-bar").addClass("red");
                    $(".modal-bar").removeClass("green");

                    $("#msg_header").text("Error");
                    $("#msg_body").text("Message was not sent!");
                }
            });
            // no need to submit form 
            // form.submit();

        }
    });
});


// Close modal funciton
$("#modal-container").click(function() {

    $(".modal-background").addClass("out");
    $(".modal-background").removeClass("show");
    setTimeout(function() {
        $("#modal-container").removeClass("show");
        $(".modal-background").removeClass("out");
    }, 500);

});


///============================================
///=====             MENU            ==========
///============================================
$('.toggle>input').change(function() {
    // $(this).next().toggle(this.checked);
    // console.log('Clicked')
    chckd = $(this).is(':checked');
    if (chckd) {
        // console.log('menu open');
        $("div.menu-overlay").addClass("open");
        $("div.nav-container").addClass("open");
        $('.mobile-menu>.mobile-menu-item').addClass("show");
    } else {
        // console.log('menu closed');
        $("div.menu-overlay").removeClass("open");
        $("div.nav-container").removeClass("open");
        $('.mobile-menu>.mobile-menu-item').removeClass("show");
    }
});

$('.mobile-menu-item>a').click(function() {
    // chckd = $(this).is(':checked');
    // if (chckd) {
    //     // console.log('menu open');
    //     $("div.menu-overlay").addClass("open");
    //     $("div.nav-container").addClass("open");
    //     $('.mobile-menu>.mobile-menu-item').addClass("show");
    // } else {
    // console.log('menu closed');
    $("div.menu-overlay").removeClass("open");
    $("div.nav-container").removeClass("open");
    $('.mobile-menu>.mobile-menu-item').removeClass("show");
    // }
    $('.toggle>input').prop("checked", false);

});

// $("div.menu-overlay").toggleClass("open");
// $('menu-overlay').toggleClass("open");
// }).change();